import React from "react";
import "./Footer.scss";
import instaLogo from "../images/instagram.png";
import email from "../images/email.png";
import telefone from "../images/telefone.png";
import endereco from "../images/endereco.png";
import whatsappLogo from "../images/whatsapp.png";
import mascote from '../images/logo-header.png'
import logoOA from '../images/logo-oa.png'
import { Link } from 'react-router-dom'

function Footer() {
  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  const year = new Date().getFullYear();
  return (
    <div id="footer">
      <h2>Contato</h2>
      <a className="whatsapp" href="https://api.whatsapp.com/send?phone=5511932878332&text=Olá. Entrei no site mascotetop e gostaria de receber mais informações para orçamento!" target="blank" title="Botão para solicitar orçamento">
        <img src={whatsappLogo} alt="wehatssApp" />
      </a>
      <a className="btn-orcamento" href="https://api.whatsapp.com/send?phone=5511932878332&text=Olá. Entrei no site mascotetop e gostaria de receber mais informações para orçamento!" target="blank" title="Botão para solicitar orçamento">
        <p>SOLICITE SEU ORÇAMENTO</p>
      </a>
      <div className="wrapper">


        <div className="info redes">
          <h3>Redes Sociais</h3>
          <div className="links">
            <a
              href="https://www.instagram.com/oacomunicacaodigital/"
              target="blank"
            >
              <img src={instaLogo} alt="ícone instagram" />
            </a>
          </div>
        </div>
        <div className="info mail">
          <h3>E-mail </h3>
          <a href="mailto:contato@mascotetop.com">
            <img src={email} alt="ícone email" /> &nbsp;
            contato@mascotetop.com
          </a>
        </div>
        <div className="info tel">
          <h3>Telefone </h3>
          <a href="tel:+5511932878332">
            <img src={telefone} alt="ícone telefone" />
            <p>+55 11 93287-8332</p></a>
        </div>
        <div className="info">
          <h3>Endereço </h3>
          <p> <img src={endereco} alt="ícone endereço" />Avenida Paulista, 1636</p>
          <p className="ml">Conj 4 Pavmto15 Sala 1504</p>
          <p className="ml"> Bela Vista, São Paulo - Brasil</p>
        </div>
      </div>
      <div className="w_logos">
        <Link className="logo" to="/" title="link para home" onClick={scrollToTop}>
          <img src={mascote} alt='Logo Mascote Top' />
        </Link>
        <a className="logo_oa" href="https://www.oacomunicacaodigital.com.br/" target="blank" title="link para home">
          <img src={logoOA} alt='Logo OA Comunicação' />
        </a>
      </div>
      <div className="copy">
        <p>CNPJ: 57.468.484/0001-15 <span className="desktop">&nbsp;&nbsp;&nbsp;</span> <br className="mobile" /> © OA Comunicação {year}. Todos os direitos reservados.</p>
      </div>
    </div>
  );
}

export default Footer;
